<div class="section-card">
  <div class="row m-3" style="justify-content: end;">
    <div class="col-10"></div>
    <button class="col-2 btn btn-primary" style="float: right; width: 100px;"
      routerLink="/acr-database/acr-details/details/Job">
      Back
    </button>
  </div>
  <div class="row FlatCard shadow border-radius6 m-3">
    <div class="card-header">
      <h3 class="card-title">Applied Applicants Details</h3>
    </div>
    <div class="card-body p-3">
      <div>
        <table class="table">
          <thead class="text-black">
            <tr>
              <th style="text-align: center;">Sr. No</th>
              <th style="text-align: center;">Applicant Name</th>
              <th style="text-align: center;">No of Resources</th>
              <th style="text-align: center;">Email ID</th>
              <th style="text-align: center;">Phone No.</th>
              <th style="text-align: center;">Documents</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let applicant of tableData; let index = index">
              <tr>
                <td style="text-align: center;">{{ index + 1 }}</td>
                <td style="text-align: center;">{{ applicant?.userInfo?.agencyName }}</td>
                <td style="text-align: center;">{{ applicant?.no_of_resouces }}</td>
                <td style="text-align: center;">{{ applicant?.userInfo?.personEmail }}</td>
                <td style="text-align: center;">{{ applicant?.userInfo?.phoneNumber }}</td>
                <td style="text-align: center;">
                  <ng-container *ngFor="let cvData of applicant?.cvDetails; let cvIndex = index">
                    <button
                      class="btn btn-sm btn-primary m-1"
                      (click)="openDocument(cvData?.cv?.url)">
                      Document {{ cvIndex + 1 }}
                    </button>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        
      </div>
    </div>
  </div>
</div>