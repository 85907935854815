<section class="section-card">
  <div class="container">
    <div class="row">
      <h4 class="text-center mb-4 text-bold"> Refer a Friend and Earn Rewards! </h4>
      <p class="text-center mb-4 text-bold">
        <br>
        You are ONE step a away from your recurring income - we value the skills, so we reward you for life of any
        referee engagement.
        <br>
        Unlike any standard skills referrals, we offer an ongoing daily referral thank you fee, for each day we engage
        your referral on any contract role.
        <br>
        Exciting!! Please refer your colleagues (as many as you wish) and inform them to complete the registration using
        the referral code given to you.
      </p>
      <div class="col-2">
        <div class="FlatCard shadow border-radius6 p-0">
          <div class="card-header text-center">
            <h4>Your Referal Code</h4>
          </div>
          <div class="card-body text-center p-3">
            <h4>{{referredBy}}</h4>
          </div>
        </div>
      </div>
      <div class="col-10">
        <div class="FlatCard shadow border-radius6 p-0">
          <div class="card-header text-center">
            <h4>Refer & Earn</h4>
          </div>
          <div class="card-body text-center p-3">
            <form [formGroup]="referForm">
              <table class="table table-bordered">
                <thead class="card-header">
                  <tr>
                    <th scope="col">Sr. No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email Id</th>
                    <th scope="col">Job Title</th>
                  </tr>
                </thead>
                <tbody formArrayName="candidates">
                  <tr *ngFor="let candidate of candidates.controls; let i = index" [formGroupName]="i">
                    <td>{{ i + 1 }}.</td>
                    <td><input type="text" placeholder="Enter name" class="form-control" formControlName="name" />
                      <span class="text-danger text-left d-flex justify-content-left" *ngIf="
                        referForm.controls?.['name']?.touched &&
                        referForm.controls?.['name']?.errors?.['required']">
                        Please enter name.
                      </span>
                    </td>
                    <td><input type="email" placeholder="Enter email id" class="form-control" formControlName="email" />
                      <span class="text-danger text-left d-flex justify-content-left" *ngIf="
                        referForm.controls?.['email']?.touched &&
                        referForm.controls?.['email']?.errors?.['required']">
                        Please enter email.
                      </span>
                      <span class="text-danger text-left d-flex justify-content-left" *ngIf="
                        referForm.controls?.['email']?.touched &&
                        referForm.controls?.['email']?.errors?.['pattern']">
                        Please enter valid email.
                      </span>
                    </td>
                    <td><input type="text" placeholder="Enter job title" class="form-control"
                        formControlName="job_title" />
                      <span class="text-danger text-left d-flex justify-content-left" *ngIf="
                        referForm.controls?.['job_title']?.touched &&
                        referForm.controls?.['job_title']?.errors?.['required']">
                        Please enter job title.
                      </span>
                    </td>
                    <td>
                      <button type="button" class="btn btn-danger" (click)="removeCandidate(i)"
                        *ngIf="candidates.length > 1">-</button>&nbsp;
                      <button type="button" class="btn btn-primary" (click)="addCandidate()"
                        *ngIf="i === candidates.length - 1">+</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="col-6 justify-content-between" style="float: left; margin-top: 15px;">
                <p class="text-bold">Click + if you wish to refer more than 1 person</p>
              </div>
              <div class="col-3" style="float: right;">
                <div class="d-flex align-items-center justify-content-between">
                  <button type="button" class="btn btn-primary w-50" routerLink="/cir/cir-card">Back</button>
                  <button type="button" (click)="submit()" class="btn btn-primary w-50">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>