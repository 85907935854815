<section class="section-registration">
  <div class="container">
    <div class="FlatCard shadow border-radius24">
      <div class="row">
        <div class="col-lg-5 col-xl-5 col-12">
          <div class="FormLeft-panel">
            <div class="text-center">
              <img src="../../../../assets/img/Saivenlogo.png" width="200" class="mb-5" />
            </div>
            <h1 class="HeadingLarge">“Elevate Your Career with SaiVen: Join Public Sector IT Projects”</h1>
            <p class="mb-5 textfont">SaiVen, is an emerging IT QA lead business with 40+ business collaborations
              across UK and India. We are a dedicated business to lead the governance of all public sector QA projects
              signed by the group and we are the driving force for client relationship management, project governance,
              and people/delivery management.<br><br>
              Our job is to ensure seamless execution for exceptional results, through bringing our industry expertise
              added with right skills at right time - enabling quick and cost-effective resource augmentation, for
              benefit of our clients.<br><br>Partner with us for exciting contract opportunities and be a part of our
              success
              story</p>
            <p><strong>SaiVen Technology Solutions Limited</strong><br>
              1-04, Focal Point, <br>27-35 Fleet Street, <br>Swindon SN1 1RQ
            </p>
            <p>Company Registration Number: 8856876</p>
            <p>Email: Info@saivensolutions.co.uk</p>
          </div>
        </div>
        <div class="col-lg-7 col-xl-7 col-12">
          <div class="FormRight-panel">
            <h4 class="mb-3"><strong>ACR Form</strong></h4>
            <form [formGroup]="agencyForm" (ngSubmit)="submit()">

              <div class="row">
                <div class="col-md-5">
                  <label>Agency Name<span style="color: red;">*</span>:</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Enter Agency Name"
                      formControlName="agencyName">
                    <span class="text-danger"
                      *ngIf="agencyForm.get('agencyName')?.touched && agencyForm.get('agencyName')?.errors?.['required']">
                      Please enter the supplier or agency name.
                    </span>
                    <span class="text-danger"
                      *ngIf="agencyForm.get('agencyName')?.touched && agencyForm.get('agencyName')?.errors?.['pattern']">
                      Please enter a valid format for the supplier or agency name.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Location<span style="color: red;">*</span>:</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Enter your post-code/address"
                      formControlName="location">
                    <span class="text-danger"
                      *ngIf="agencyForm.get('location')?.touched && agencyForm.get('location')?.errors?.['required']">
                      Please enter location.
                    </span>
                    <span class="text-danger"
                      *ngIf="agencyForm.get('location')?.touched && agencyForm.get('location')?.errors?.['pattern']">
                      Please enter valid location.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Number Of Branches in UK<span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" (keypress)="NumberOnly($event)"
                      formControlName="numberOfBranchesInUK" placeholder="Number Of Branches in UK">
                    <span class="text-danger" *ngIf="
                      agencyForm.controls?.['numberOfBranchesInUK']?.touched &&
                      agencyForm.controls?.['numberOfBranchesInUK']?.errors?.['required']">
                      Please enter no of branches.
                    </span>
                    <span class="text-danger" *ngIf="
                      agencyForm.controls?.['numberOfBranchesInUK']?.touched &&
                      agencyForm.controls?.['numberOfBranchesInUK']?.errors?.['pattern']">
                      Please enter valid no Of branches.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Primary Person Name<span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" formControlName="personName"
                      placeholder="Primary Person Name">
                    <span class="text-danger" *ngIf="
                      agencyForm.controls?.['personName']?.touched &&
                      agencyForm.controls?.['personName']?.errors?.['required']">
                      Please enter Primary Person name.
                    </span>
                    <span class="text-danger" *ngIf="
                      agencyForm.controls?.['personName']?.touched &&
                      agencyForm.controls?.['personName']?.errors?.['pattern']">
                      Please enter valid Primary Person name.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Primary Person Designation<span style="color: red;">*</span>
                    :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Primary Person Designation"
                      formControlName="personDesignation">
                    <span class="text-danger" *ngIf="
                        agencyForm.controls?.['personDesignation']?.touched &&
                        agencyForm.controls?.['personDesignation']?.errors?.['required']">
                      Please enter Primary Person designation.
                    </span>
                    <span class="text-danger" *ngIf="
                        agencyForm.controls?.['personDesignation']?.touched &&
                        agencyForm.controls?.['personDesignation']?.errors?.['pattern']">
                      Please enter valid Primary Person designation.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Primary Person Email<span style="color: red;">*</span>
                    :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Primary Person Email"
                      formControlName="personEmail">
                    <span class="text-danger" *ngIf="
                    agencyForm.controls?.['personEmail']?.touched &&
                    agencyForm.controls?.['personEmail']?.errors?.['required']">
                      Please enter Primary Person email.
                    </span>
                    <span class="text-danger" *ngIf="
                    agencyForm.controls?.['personEmail']?.touched &&
                    agencyForm.controls?.['personEmail']?.errors?.['pattern']">
                      Please enter valid Primary Person email.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Primary Person phone number<span style="color: red;">*</span>
                    :</label>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Code like +44" formControlName="phoneNumberCountryCode">
                    <span class="text-danger" *ngIf="
                      agencyForm.controls?.['phoneNumberCountryCode']?.touched &&
                      agencyForm.controls?.['phoneNumberCountryCode']?.errors?.['required']">
                      Please enter the country code.
                    </span>
                    <span class="text-danger" *ngIf="
                      agencyForm.controls?.['phoneNumberCountryCode']?.touched &&
                      agencyForm.controls?.['phoneNumberCountryCode']?.errors?.['pattern']">
                      Please enter a valid format for the country code.
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="tel" class="form-control" placeholder="Primary Person phone number" [maxlength]="10"
                      (keypress)="NumberOnly($event)" formControlName="phoneNumber">
                    <span class="text-danger" *ngIf="
                    agencyForm.controls?.['phoneNumber']?.touched &&
                    agencyForm.controls?.['phoneNumber']?.errors?.['required']">
                      Please enter Primary Person phone number.
                    </span>
                    <span class="text-danger" *ngIf="
                    agencyForm.controls?.['phoneNumber']?.touched &&
                    agencyForm.controls?.['phoneNumber']?.errors?.['pattern']">
                      Please enter valid Primary Person phone number.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Secondary Contact Name
                    :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Secondary Contact Name"
                      formControlName="secondaryPersonName">

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Secondary Contact Designation:</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Secondary Contact Designation"
                      formControlName="secondaryPersonDesignation">

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Secondary Contact Email
                    :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Secondary Contact Email"
                      formControlName="secondaryPersonEmail">

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Secondary Contact Phone Number:</label>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Code like +44"
                      formControlName="secondaryPhoneNumberCountryCode">

                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="tel" class="form-control" placeholder="Secondary Contact Phone Number" [maxlength]="10"
                      (keypress)="NumberOnly($event)" formControlName="secondaryPersonPhone">
                    <span class="text-danger" *ngIf="
                    agencyForm.controls?.['secondaryPersonPhone']?.touched &&
                    agencyForm.controls?.['secondaryPersonPhone']?.errors?.['required']">
                      Please enter Secondary Contact phone number.
                    </span>
                    <span class="text-danger" *ngIf="
                    agencyForm.controls?.['secondaryPersonPhone']?.touched &&
                    agencyForm.controls?.['secondaryPersonPhone']?.errors?.['pattern']">
                      Please enter valid Secondary Contact phone number.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Preferred Contact Time<span style="color: red;">*</span>:</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <ng-multiselect-dropdown [placeholder]="'Contact Time'" [settings]="dropdownSettings"
                      [data]="timeSlots" formControlName="callTime" (onSelect)="onItemSelect($event)"
                      (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                    <span class="text-danger"
                      *ngIf="agencyForm.controls?.['callTime']?.touched && agencyForm.controls?.['callTime']?.errors?.['required']">
                      Please enter contact time.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">

                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <re-captcha (resolved)="onCaptchaResolved($event)" siteKey="6LfYpIcqAAAAAISPyVivnE5oO7O_QoFyo9Uiu8it"
                      class="mb-3">
                    </re-captcha>
                    <span class="text-danger" *ngIf="captchaError">
                      Please complete the CAPTCHA.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="update ml-auto mr-auto"><br>
                  <button style="float: right !important;" type="submit"
                    class="btn btn-primary btn-round"  [disabled]="!captchaToken">Next</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
