<section class="section-registration">
  <div class="container">
    <div class="FlatCard shadow border-radius24">
      <div class="row">
        <div class="col-lg-5 col-xl-5 col-12">
          <div class="FormLeft-panel">
            <div class="text-center">
              <img src="../../../../assets/img/Saivenlogo.png" width="200" class="mb-5" />
            </div>
            <h1 class="HeadingLarge">“Elevate Your Career with SaiVen: Join Public Sector IT Projects”</h1>
            <p class="mb-5 textfont">SaiVen, is an emerging IT QA lead business with 40+ business collaborations
              across UK and India. We are a dedicated business to lead the governance of all public sector QA projects
              signed by the group and we are the driving force for client relationship management, project governance,
              and people/delivery management.<br><br>
              Our job is to ensure seamless execution for exceptional results, through bringing our industry expertise
              added with right skills at right time - enabling quick and cost-effective resource augmentation, for
              benefit of our clients.<br><br>Partner with us for exciting contract opportunities and be a part of our success
              story</p>
            <p><strong>SaiVen Technology Solutions Limited</strong><br>
              1-04, Focal Point, <br>27-35 Fleet Street, <br>Swindon SN1 1RQ
            </p>
            <p>Company Registration Number: 8856876</p>
            <p>Email: Info@saivensolutions.co.uk</p>
          </div>
        </div>
        <div class="col-lg-7 col-xl-7 col-12">
          <div class="FormRight-panel">
            <h4><strong>Additional details</strong></h4>
            <form [formGroup]="otherDetailForm" (ngSubmit)="submit()">
              <div class="row mb-3">
                <div class="col-lg-4">
                  <label>Upload CV <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-lg-8">
                  <div class="form-file">
                    <input type="file" aria-label="file example" class="form-control" (change)="fileUpload($event)"
                      accept=".doc, .docx,.pdf">
                    <!-- {{file?.name}} -->
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-4">
                  <label>Work Location Preferences <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <label>
                      <input type="checkbox" name="work_location" value="client-location"
                        (change)="onCheckboxChange($event)" class="chckbox mr-1">&nbsp;
                      <span style="font-weight: normal;"> I am ok to work at Client location</span>
                    </label><br>
                    <label>
                      <input type="checkbox" name="work_location" value="work-from-home-on-demand"
                        (change)="onCheckboxChange($event)" class="chckbox mr-1">&nbsp;
                      <span style="font-weight: normal;"> I would like to work from home with on-demand reporting to
                        location</span>
                    </label><br>
                    <label>
                      <input type="checkbox" name="work_location" value="100-percent-remote"
                        (change)="onCheckboxChange($event)" class="chckbox mr-1">&nbsp;
                      <span style="font-weight: normal;"> I would like to work 100% Remotely</span>
                    </label>
                    <span class="text-danger" *ngIf="
                      otherDetailForm.controls?.['workLocation']?.touched &&
                      otherDetailForm.controls?.['workLocation']?.errors?.['required']">
                      Please enter workLocation.
                    </span>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-4">
                  <label>Your expected day rate<span style="color: red;">*</span>:</label>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Enter your expected day rate"
                      formControlName="expectedDayRate" (keypress)="NumberOnly($event)">
                    <span class="text-danger" *ngIf="
                          otherDetailForm.controls?.['expectedDayRate']?.touched &&
                          otherDetailForm.controls?.['expectedDayRate']?.errors?.['required']">
                      Please enter your expected day rate.
                    </span>
                    <span class="text-danger" *ngIf="
                          otherDetailForm.controls?.['expectedDayRate']?.touched &&
                          otherDetailForm.controls?.['expectedDayRate']?.errors?.['pattern']">
                      Please enter your valid expected day rate' .
                    </span>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-4">
                  <label>Referred by <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <select class="form-control" formControlName="referredBy">
                      <option value="" disabled selected>000</option>
                      <option *ngFor="let option of referredByOptions" [value]="option">
                        {{ option | number: '3.0' }}
                      </option>
                    </select>
                    <p class="text-warning"><i class='fas fa-exclamation-triangle'></i> Below Referred By message If you
                      have been referred by someone, please select their Referral
                      Code;
                      If not referred by anyone leave it as 000.</p>
                    <span class="text-danger" *ngIf="
                    otherDetailForm.controls?.['referredBy']?.touched &&
                    otherDetailForm.controls?.['referredBy']?.errors?.['required']">
                      Please enter referredBy.
                    </span>
                    <span class="text-danger" *ngIf="
                    otherDetailForm.controls?.['referredBy']?.touched &&
                    otherDetailForm.controls?.['referredBy']?.errors?.['pattern']">
                      Please enter a valid referredBy.
                    </span>
                  </div>
                </div>
              </div>

              <!-- SC/DV clearance -->
              <div class="row">
                <div class="col-md-4">
                  <label>Do you hold any SC/DV clearance? <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <select class="form-control" formControlName="sc_dv_clearance_hold">
                      <option value="" disabled selected hidden>Select Type</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    <span class="text-danger" *ngIf="
                      otherDetailForm.controls?.['sc_dv_clearance_hold']?.touched &&
                      otherDetailForm.controls?.['sc_dv_clearance_hold']?.errors?.['required']">
                      Please select a SC/DV clearance.
                    </span>
                  </div>
                </div>
              </div>

              <!-- answer is NO to above -->
              <div class="row" *ngIf="otherDetailForm.get('sc_dv_clearance_hold')?.value === 'no'">
                <div class="col-md-4">
                  <label>Are you willing to undertake if/when we sponsor it? <span
                      style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <select class="form-control" formControlName="willing_to_undertake">
                      <option value="" disabled selected hidden>Select Type</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                      <option value="might_not_be_eligible">I might not be eligible</option>
                      <option value="dont_know"> don't know</option>
                    </select>
                    <span class="text-danger" *ngIf="
                      otherDetailForm.controls?.['willing_to_undertake']?.touched &&
                      otherDetailForm.controls?.['willing_to_undertake']?.errors?.['required']">
                      Please select a value.
                    </span>
                  </div>
                </div>
              </div>
              <br>
              <div class="row mb-3">
                <div class="col-lg-4">
                  <label>Preferred contact Time<span style="color: red;">*</span>:</label>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <!-- <ng-select [items]="timeSlots" bindLabel="label" bindValue="value" formControlName="callTime"
                      class="form-select custom-ng-select-option" [multiple]="true">
                    </ng-select> -->
                    <ng-multiselect-dropdown [placeholder]="'contact Time'" [settings]="dropdownSettings"
                      [data]="timeSlots" formControlName="callTime" (onSelect)="onItemSelect($event)"
                      (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                    <span class="text-danger" *ngIf="
                    otherDetailForm.controls?.['callTime']?.touched &&
                    otherDetailForm.controls?.['callTime']?.errors?.['required']">
                      Please enter callTime.
                    </span>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-4">
                  <label>Preferred contact Day <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <!-- <ng-select [items]="daysOfWeek" bindLabel="label" bindValue="value" formControlName="callDay"
                      class="form-select custom-ng-select-option" [multiple]="true">
                    </ng-select> -->
                    <ng-multiselect-dropdown [placeholder]="'contact Day'" [settings]="dropdownSettings"
                      [data]="daysOfWeek" formControlName="callDay" (onSelect)="onItemSelect($event)"
                      (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                    <span class="text-danger" *ngIf="
                    otherDetailForm.controls?.['callDay']?.touched &&
                    otherDetailForm.controls?.['callDay']?.errors?.['required']">
                      Please enter callDay.
                    </span>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-4">
                  <label>Password <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-lg-8">
                  <div class="input-group mb-0">
                    <input [type]="password" class="form-control" placeholder="Password" formControlName="password"
                      required>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span *ngIf="!showPassword" class="fas fa-eye" (click)="showHidePass('password')"></span>
                        <span *ngIf="showPassword" class="fas fa-eye-slash" (click)="showHidePass('password')"></span>
                      </div>
                    </div>
                  </div>
                  <span class="text-danger" *ngIf="
                    otherDetailForm.controls?.['password']?.touched &&
                    otherDetailForm.controls?.['password']?.errors?.['required']">
                    Please enter password.
                  </span>
                  <span class="text-danger" *ngIf="
                    otherDetailForm.controls?.['password']?.touched &&
                    otherDetailForm.controls?.['password']?.errors?.['pattern']">
                    Password must be at least 8 characters long, contain an uppercase letter, a number, and a special
                    character.
                  </span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-4">
                  <label>Confirm Password <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-lg-8">
                  <div class="input-group mb-0">
                    <input [type]="password" class="form-control" placeholder="Password"
                      formControlName="confirmPassword" required>
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span *ngIf="!confirmShowPassword" class="fas fa-eye"
                          (click)="showHidePass('confirmPassword')"></span>
                        <span *ngIf="confirmShowPassword" class="fas fa-eye-slash"
                          (click)="showHidePass('confirmPassword')"></span>
                      </div>
                    </div>
                  </div>
                  <span class="text-danger" *ngIf="
                    otherDetailForm.controls?.['confirmPassword']?.touched &&
                    otherDetailForm.controls?.['confirmPassword']?.errors?.['required']">
                    Please enter confirm password.
                  </span>
                  <span class="text-danger" *ngIf="
                    otherDetailForm.controls?.['confirmPassword']?.touched &&
                    otherDetailForm.controls?.['confirmPassword']?.errors?.['pattern']">
                    Password must be at least 8 characters long, contain an uppercase letter, a number, and a special
                    character.
                  </span>
                  <span class="text-danger" *ngIf="
                    otherDetailForm.controls?.['confirmPassword']?.touched &&
                    otherDetailForm.controls?.['confirmPassword']?.errors?.['notSame']">
                    Password and Confirm password do not match.
                  </span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-4">
                  <label>Any question for us? : </label>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <textarea type="text" class="form-control" placeholder="Any question for us ?"
                      formControlName="anyQuestion"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 update">
                  <button style="float: left !important;" class="btn btn-primary" routerLink="/cir/cir-accordian-card-details" type="button">Back</button>
                  <button style="float: right !important;" class="btn btn-primary" type="submit">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
