<section class="section-registration">
  <div class="container">
    <div class="FlatCard shadow border-radius24">
      <div class="row">
        <!-- Left Panel -->
        <div class="col-lg-5 col-xl-5 col-12">
          <div class="FormLeft-panel">
            <div class="text-center">
              <img src="../../../../assets/img/Saivenlogo.png" width="200" class="mb-5" />
            </div>
            <h1 class="HeadingLarge">“Elevate Your Career with SaiVen: Join Public Sector IT Projects”</h1>
            <p class="mb-5 textfont">SaiVen, is an emerging IT QA lead business with 40+ business collaborations
              across UK and India. We are a dedicated business to lead the governance of all public sector QA projects
              signed by the group and we are the driving force for client relationship management, project governance,
              and people/delivery management.<br><br>
              Our job is to ensure seamless execution for exceptional results, through bringing our industry expertise
              added with right skills at right time - enabling quick and cost-effective resource augmentation, for
              benefit of our clients.<br><br>Partner with us for exciting contract opportunities and be a part of our
              success
              story</p>
            <p><strong>SaiVen Technology Solutions Limited</strong><br>
              1-04, Focal Point, <br>27-35 Fleet Street, <br>Swindon SN1 1RQ
            </p>
            <p>Company Registration Number: 8856876</p>
            <p>Email: Info@saivensolutions.co.uk</p>
          </div>
        </div>

        <!-- Right Panel (Personal Details Form) -->
        <div class="col-lg-7 col-xl-7 col-12">
          <div class="FormRight-panel">
            <h4 class="mb-5"><strong>Profile</strong></h4>
            <form [formGroup]="agencyForm" (ngSubmit)="submit()">

              <div class="row mb-3">
                <div class="col-lg-4">
                  <label>Profile Picture :</label>
                </div>
                <div class="profile-avtar mt-4 mb-4">
                  <img *ngIf="file?.url || loginDetails.profile.url"
                    [src]="file?.url || loginDetails.profile.url || 'assets/img/default-avatar.png'" />

                  <div class="input--file">
                    <i class="bi bi-camera-fill">+</i>
                    <input name="Select File" type="file" (change)="fileUpload($event)"
                      accept="image/png, image/gif, image/jpeg">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Agency Name:</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Enter Agency Name"
                      formControlName="agencyName" readonly>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Location:</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Enter your post-code/address"
                      formControlName="location" >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Primary Person Name :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" formControlName="personName"
                      placeholder="Primary Person Name" >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Primary Person Designation
                    :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Primary Person Designation"
                      formControlName="personDesignation" >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Primary Person Email
                    :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Primary Person Email"
                      formControlName="personEmail" >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Primary Person Phone No.
                    :</label>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Code like +44"
                      formControlName="phoneNumber" >
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="tel" class="form-control" placeholder="Primary Person phone no." [maxlength]="10"
                      (keypress)="NumberOnly($event)" formControlName="phoneNumber" >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Secondary Contact Person Name
                    :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Secondary Contact Name"
                      formControlName="secondaryContectName" >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Secondary Contact Person Designation
                    :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Secondary Contact Designation"
                      formControlName="secondaryDesignation" >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Secondary Contact Person Email
                    :</label>
                </div>
                <div class="col-md-7">
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Secondary Contact Email"
                      formControlName="secondaryEmail" >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <label>Secondary Contact Person Phone No.
                    :</label>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Code like +44"
                      formControlName="secondaryPhoneNumberCountryCode" >
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="tel" class="form-control" placeholder="Secondary Contact Phone No" [maxlength]="10"
                      (keypress)="NumberOnly($event)" formControlName="secondaryPhoneNumber" >
                  </div>
                </div>
              </div>

              <div class="row mt-4 mb-3">
                <div class="col-12 mb-3">
                  <h4 type="button" class="btn btn-primary" (click)="togglePasswordFields()"> Update Password</h4>
                </div>
              </div>

              <div *ngIf="showPasswordFields">
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label for="password">Password:</label>
                  </div>
                  <div class="col-md-8">
                    <input id="password" type="password" class="form-control" placeholder="Password"
                      formControlName="password">
                    <span *ngIf="!showPassword" class="fas fa-eye" (click)="showHidePass('password')"></span>
                    <span *ngIf="showPassword" class="fas fa-eye-slash" (click)="showHidePass('password')"></span>
                    <span class="text-danger"
                      *ngIf="agencyForm?.controls?.['password']?.touched && agencyForm?.controls?.['password']?.errors?.['required']">
                      Please enter a password.
                    </span>
                    <span class="text-danger"
                      *ngIf="agencyForm?.controls?.['password']?.touched && agencyForm?.controls?.['password']?.errors?.['pattern']">
                      Password must contain a capital letter, a number, and '@'.
                    </span>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-4">
                    <label for="confirmPassword">Confirm Password:</label>
                  </div>
                  <div class="col-md-8">
                    <input id="confirmPassword" type="password" class="form-control" placeholder="Confirm Password"
                      formControlName="confirmPassword">
                    <span *ngIf="!confirmShowPassword" class="fas fa-eye"
                      (click)="showHidePass('confirmPassword')"></span>
                    <span *ngIf="confirmShowPassword" class="fas fa-eye-slash"
                      (click)="showHidePass('confirmPassword')"></span>
                    <span class="text-danger"
                      *ngIf="agencyForm?.controls?.['confirmPassword']?.touched && agencyForm?.controls?.['confirmPassword']?.errors?.['required']">
                      Please confirm your password.
                    </span>
                    <span class="text-danger"
                      *ngIf="agencyForm?.controls?.['confirmPassword']?.touched && agencyForm?.controls?.['confirmPassword']?.errors?.['pattern']">
                      Password must contain a capital letter, a number, and '@'.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="update ml-auto mr-auto"><br>
                  <button style="float: right !important;" type="submit"
                    class="btn btn-primary btn-round">Update</button>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- End Form -->
      </div>
    </div>
  </div>
</section>
