<section class="section-accordian-card">
  <div class="container">
    <h4 style="text-align: center;"><strong>All Jobs</strong></h4><br>
    <div class="alert alert-warning" style="text-align: center; font-size: 19px  !important;" role="alert">
      <i class="bi bi-exclamation-triangle"></i><strong>⁠It is important for you read the <span
          style="color: #34b5b8 !important;">Read me </span>document for each job title before you take action.</strong>
    </div>
    <div class="row">
      <div class="col-lg-6 col-xl-6 col-12 mob-mb1">
        <div class="form-group mb-0">
          <span class="input-icon"><i class="bi bi-search"></i></span>
          <input class="form-control control-search" placeholder="Search by Job Title" type="text"
            [(ngModel)]="searchText" (input)="searchtext()">
        </div>
      </div>
      <div class="col-lg-4 col-xl-4 col-12 mob-mb1">
        <div class="form-group mb-0">
          <select class="form-control" [(ngModel)]="selectedStatus" (change)="searchtext()">
            <option value="" disabled>Select Job Type</option>
            <option *ngFor="let status of statusList" [value]="status">{{status}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th style="text-transform: none; text-align: center;">Job ID</th>
            <th style="text-transform: none; text-align: center;" scope="col">Job Title</th>
            <th style="text-transform: none; text-align: center;" scope="col">No. of Roles</th>
            <th style="text-transform: none; text-align: center;" scope="col">Publish Date</th>
            <th style="text-transform: none; text-align: center;" [width]="100" scope="col">Role Details</th>
            <th style="text-transform: none; text-align: center;" [width]="200" scope="col">Apply Job</th>
            <th style="text-transform: none; text-align: center;" [width]="100" scope="col">Status</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td *ngIf="joblist.length == 0" class="text-center" colspan="10">
              No Record Found.
            </td>
          </tr>

          <tr
            *ngFor="let job of joblist  |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
            <td>{{job.job_id}}</td>
            <td>{{job.job_title}}</td>
            <td>{{job.no_of_roles}}</td>
            <td>{{job.publish_date | date:'dd/MM/yyyy'}}</td>
            <td>
              <a href="{{job?.upload?.url}}" target="_blank">
                Read me
              </a>
            </td>
            <td class="text-center">
              <ng-container *ngIf="job.status !== 'Under Review'; else emailMessage">
                <div *ngIf="!job.applied">
                  <button  class="btn btn-primary w-50"
                    [ngClass]="{'enabled-text': !(job.status === 'Actioned' || job.status === 'Expired' || job.status === 'Inactive'), 'disabled-text': job.status === 'Actioned' || job.status === 'Expired' || job.status === 'Inactive'}" 
                    [disabled]="job.status === 'Applied' || job.status === 'Expired' || job.status === 'Inactive'"
                    (click)="openModal(job)">
                    apply
                  </button>
                </div>
                <div *ngIf="job.applied == true">
                  <div class="row">
                    <div class="ml-auto mr-auto">
                      <button (click)="openCVModal(job)" type="submit" class="btn btn-primary btn-round"
                        [disabled]="job.status === 'Not Submitted'">Upload
                        CV</button>
                    </div>
                  </div>
                </div>
              </ng-container>


              <ng-template #emailMessage>
                <span> If you have got more CV's email to : (jamie.thompson@saivensolutions.co.uk)</span>
              </ng-template>
            </td>
            <td>{{job?.status}}</td>

          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-3 align-items-center">
      <div class="row">
        <div class="pagination">
          <pagination-controls (pageChange)="paginate($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template class="modal" #loginDetailModal id="loginDetailModal" tabindex="-1" aria-labelledby="ViewLogin"
  aria-hidden="true">
  <div class="">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewLogin">Upload Details</h5>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="resourcesForm">

          <div class="row">
            <div class="row mt-3">
              <div class="col-md-4">
                <label>Choose CV option: <span style="color: red;"> *</span></label>
              </div>
              <div class="col-md-8">
                <div class="form-group d-flex align-items-center">

                  <label class="me-3">
                    <input type="radio" id="1" name="currentWork" value="existing-cv" formControlName="currentWork"
                      (change)="onSelectExistingCV()">&nbsp;
                    <span style="font-weight: normal;">USE EXISTING CV</span>
                  </label>

                  <label>
                    <input type="radio" id="2" name="currentWork" value="upload-cv" formControlName="currentWork"
                      (change)="newselectedCv()">&nbsp;
                    <span style="font-weight: normal;">UPLOAD NEW CV</span>
                  </label>
                </div>

                <div *ngIf="selectedCV">
                  <h5>Existing CV Details</h5>
                  <p><strong>CV Name:</strong> {{selectedCV?.name}}</p>
                  <a [href]="selectedCV?.url" target="_blank" class="btn btn-info">Download CV</a>
                </div>
                <div *ngIf="newCV" class="form-file" >
                  <h5>Upload new CV :</h5>
                  <input type="file" aria-label="file example" class="form-control" (change)="fileUpload($event)"
                    accept=".doc, .docx,.pdf">
                </div>
                <br>
              </div>
            </div>
          </div>
          <div class="col-3" style="float: right;">
            <div class="d-flex align-items-center justify-content-between" *ngIf="!submitRes">
              <button type="button" (click)="submitResources()" class="btn btn-primary w-50">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>