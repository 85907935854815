<div class="wrapper">
  <div class="main-panel">
    <nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
      <div class="container-fluid">
        <div class="navbar-wrapper">
          <div class="navbar-toggle">
            <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
              <span class="navbar-toggler-bar bar1"></span>
              <span class="navbar-toggler-bar bar2"></span>
              <span class="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <a class="navbar-brand" style="color: white;" href="javascript:void(0)"
            routerLink="/cir/cir-card">{{getTitle()}}</a>
        </div>
        <button class="navbar-toggler" type="button" (click)="collapse()" [attr.aria-expanded]="!isCollapsed"
          aria-controls="collapseExample">
          <span class="navbar-toggler-bar navbar-kebab"></span>
          <span class="navbar-toggler-bar navbar-kebab"></span>
          <span class="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed">
          <ul class="navbar-nav" *ngIf="TokenData?.role == 'CIR'">
            <li class="nav-item">
              <a class="nav-link btn-magnify" routerLink="/cir/cir-card">
                <p>Future Roles</p>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link btn-magnify" routerLink="/cir/cir-active-roles">
                <p>Active Roles</p>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link btn-magnify" routerLink="/cir/cir-refer-earn">
                <p>Refer & Earn</p>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link btn-magnify" href="javascript:void(0)">
                <p>{{ loginUser?.name }}</p>
                <p>
                  <span class="d-lg-none d-md-block">{{ loginUser?.name }}</span>
                </p>
              </a>
            </li>

            <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
              <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink">
                <i class="nc-icon nc-settings-gear-65"></i>
                <p>
                  <span class="d-lg-none d-md-block">Account</span>
                </p>
              </a>
              <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-end">
                <a ngbDropdownItem routerLink="/cir/cir-profile">Profile</a>
                <a ngbDropdownItem href="javascript:void(0)" (click)="logout()"> LogOut </a>
              </div>
            </li>
          </ul>
          <ul class="navbar-nav" *ngIf="TokenData?.role != 'CIR'">
            <li class="nav-item">
              <button (click)="logoutAdmin()" style="float: right !important;" class="btn btn-primary btn-round">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
                  <path fill-rule="evenodd"
                    d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                </svg> Log Out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>