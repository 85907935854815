// export const environment = {
// 	production: false,

// 	url: "http://localhost:4200",
// 	baseUrl: 'http://82.112.230.8:5010/api/v1/'
//   };


export const environment = {
	production: false,

	url: "http://localhost:4200",
	baseUrl: 'https://api.saivensolutions.co.uk/api/v1'
};
