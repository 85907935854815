<header class="header-sticky" style="background-color: #007F83 
;">
    <div class="top-header">
        <div class="d-flex justify-content-between container-fluid ps-lg-3">
            <div class="align-items-center justify-content-between" style="width: 308px; padding-left: 80px;">
             <img style="height: 3rem;" src="../../../../assets/img/Saivenlogo.png"  />
            </div>
            <div >
            </div>
        </div>
    </div>
</header>