<section class="section-registration">
  <div class="container">
    <div class="FlatCard shadow border-radius24 mobile-registration-card">
      <div class="row">
        <div class="col-lg-5 col-xl-5 col-12">
          <div class="FormLeft-panel">
            <div class="text-center">
              <img src="../../../../assets/img/Saivenlogo.png" width="200" class="mb-5" />
            </div>
            <h1 class="HeadingLarge">“Elevate Your Career with SaiVen: Join Public Sector IT Projects”</h1>
            <p class="mb-5 textfont">SaiVen, is an emerging IT QA lead business with 40+ business collaborations
              across UK and India. We are a dedicated business to lead the governance of all public sector QA projects
              signed by the group and we are the driving force for client relationship management, project governance,
              and people/delivery management.<br><br>
              Our job is to ensure seamless execution for exceptional results, through bringing our industry expertise
              added with right skills at right time - enabling quick and cost-effective resource augmentation, for
              benefit of our clients.<br><br>Partner with us for exciting contract opportunities and be a part of our
              success
              story</p>
            <p><strong>SaiVen Technology Solutions Limited</strong><br>
              1-04, Focal Point, <br>27-35 Fleet Street, <br>Swindon SN1 1RQ
            </p>
            <p>Company Registration Number: 8856876</p>
            <p>Email: Info@saivensolutions.co.uk</p>
          </div>
        </div>
        <div class="col-lg-7 col-xl-7 col-12">
          <div class="FormRight-panel">
            <h4><strong>Personal Details</strong></h4><br>
            <form [formGroup]="personalDetailForm" (ngSubmit)="submitPersonalDetail()">
              <div class="row">
                <div class="col-md-4">
                  <label>Your Name <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Your Name" formControlName="name">
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['name']?.touched &&
                      personalDetailForm.controls?.['name']?.errors?.['required']">
                      Please enter your name.
                    </span>
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['name']?.touched &&
                      personalDetailForm.controls?.['name']?.errors?.['pattern']">
                      Please enter a valid format for your name.
                    </span>
                  </div>
                </div>
              </div>

              <!-- Email -->
              <div class="row">
                <div class="col-md-4">
                  <label>Your Email <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <input type="email" class="form-control" placeholder="Your Email" formControlName="email">
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['email']?.touched &&
                      personalDetailForm.controls?.['email']?.errors?.['required']">
                      Please enter your email.
                    </span>
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['email']?.touched &&
                      personalDetailForm.controls?.['email']?.errors?.['pattern']">
                      Please enter a valid email address in the format 'username@example.com'.
                    </span>
                  </div>
                </div>
              </div>

              <!-- Phone Number -->
              <div class="row">
                <div class="col-md-4">
                  <label>Your Phone number <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Code like +91 ,+41, etc."
                      formControlName="countrycode">
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['countrycode']?.touched &&
                      personalDetailForm.controls?.['countrycode']?.errors?.['required']">
                      Please enter the country code.
                    </span>
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['countrycode']?.touched &&
                      personalDetailForm.controls?.['countrycode']?.errors?.['pattern']">
                      Please enter a valid format for the country code.
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="tel" class="form-control" placeholder="Your Phone number" [maxlength]="10"
                      (keypress)="NumberOnly($event)" formControlName="phoneNumber">
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['phoneNumber']?.touched &&
                      personalDetailForm.controls?.['phoneNumber']?.errors?.['required']">
                      Please enter your phone number.
                    </span>
                  </div>
                </div>
              </div>

              <!-- Nationality and UK Visa Type -->
              <div class="row">
                <div class="col-md-4">
                  <label>Nationality <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <select class="form-control" formControlName="nationality" (change)="onNationalityChange($event)">
                      <option value="" disabled selected hidden>Select Nationality</option>
                      <option value="british">British</option>
                      <option value="other">Others</option>
                    </select>
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['nationality']?.touched &&
                      personalDetailForm.controls?.['nationality']?.errors?.['required']">
                      Please enter your nationality.
                    </span>
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['nationality']?.touched &&
                      personalDetailForm.controls?.['nationality']?.errors?.['pattern']">
                      Please enter a valid format for your nationality.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="showUKVisaType">
                <div class="col-md-4">
                  <label>UK Visa Type <span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Your Current Visa Type"
                      formControlName="UKVisaType">
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['UKVisaType']?.touched &&
                      personalDetailForm.controls?.['UKVisaType']?.errors?.['required']">
                      Please enter your Current Visa Type.
                    </span>
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['UKVisaType']?.touched &&
                      personalDetailForm.controls?.['UKVisaType']?.errors?.['pattern']">
                      Please enter a valid format for your Current Visa Type.
                    </span>
                  </div>
                </div>
              </div>

              <!-- UK Driving License -->
              <div class="row">
                <div class="col-md-4">
                  <label>Do you Have a UK Driving license?<span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <select class="form-control" formControlName="UKDrivinglicense">
                      <option value="" disabled selected hidden>Select Type</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['UKDrivinglicense']?.touched &&
                      personalDetailForm.controls?.['UKDrivinglicense']?.errors?.['required']">
                      Please select a value.
                    </span>
                  </div>
                </div>
              </div>

              <!-- Postal Code -->
              <div class="row">
                <div class="col-md-4">
                  <label>Your Current Location<span style="color: red;">*</span>:</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Enter your current city or postcode"
                      formControlName="postalCode">
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['postalCode']?.touched &&
                      personalDetailForm.controls?.['postalCode']?.errors?.['required']">
                      Please enter your postal code.
                    </span>
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['postalCode']?.touched &&
                      personalDetailForm.controls?.['postalCode']?.errors?.['pattern']">
                      Please enter a valid format for your postal code.
                    </span>
                  </div>
                </div>
              </div>

              <!-- Current Work -->
              <div class="row">
                <div class="col-md-4">
                  <label>I'm currently Working<span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label>
                      <input type="radio" id="1" name="currentWork" value="full-time"
                        formControlName="currentWork">&nbsp;
                      <span style="font-weight: normal;"> Full-time</span>
                    </label><br>
                    <label>
                      <input type="radio" id="2" name="currentWork" value="part-time"
                        formControlName="currentWork">&nbsp;
                      <span style="font-weight: normal;"> Part-time</span>
                    </label><br>
                    <label>
                      <input type="radio" id="3" name="currentWork" value="not-working"
                        formControlName="currentWork">&nbsp;
                      <span style="font-weight: normal;"> Not working</span>
                    </label>
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['currentWork']?.touched &&
                      personalDetailForm.controls?.['currentWork']?.errors?.['required']">
                      Please select your current work status.
                    </span>
                  </div>
                </div>
              </div>

              <!-- Looking For -->
              <div class="row">
                <div class="col-md-4">
                  <label>I am looking for<span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label>
                      <input type="checkbox" name="roles" value="full-time-role" (change)="onCheckboxChange($event)"
                        [checked]="selectedLookingFor('full-time-role')">&nbsp;
                      <span style="font-weight: normal;"> Full time role</span>
                    </label><br>
                    <label>
                      <input type="checkbox" name="roles" value="additional-role" (change)="onCheckboxChange($event)"
                        [checked]="selectedLookingFor('additional-role')">&nbsp;
                      <span style="font-weight: normal;"> Additional role</span>
                    </label><br>
                    <label>
                      <input type="checkbox" name="roles" value="part-time-role" (change)="onCheckboxChange($event)"
                        [checked]="selectedLookingFor('part-time-role')">&nbsp;
                      <span style="font-weight: normal;"> Part-time role</span>
                    </label><br>
                    <label>
                      <input type="checkbox" name="roles" value="freelance-role" (change)="onCheckboxChange($event)"
                        [checked]="selectedLookingFor('freelance-role')">&nbsp;
                      <span style="font-weight: normal;"> Freelancing role</span>
                    </label>
                    <span class="text-danger" *ngIf="
                      personalDetailForm.controls?.['lookingFor']?.touched &&
                      personalDetailForm.controls?.['lookingFor']?.errors?.['required']">
                      Please select what you are looking for.
                    </span>
                  </div>
                </div>
              </div>

              <!-- My current notice period (Days) -->
              <div class="row">
                <div class="col-md-4">
                  <label>My current notice period (Days)<span style="color: red;">*</span> :</label>
                </div>
                <div class="col-md-8">
                  <div class="form-group">
                    <input type="text" class="form-control" placeholder="Enter current notice period (Days)"
                      formControlName="noticePeriodDay">
                    <span class="text-danger" *ngIf="
                                    personalDetailForm.controls?.['noticePeriodDay']?.touched &&
                                    personalDetailForm.controls?.['noticePeriodDay']?.errors?.['required']">
                      Please enter your notice period.
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 update">
                  <button style="float: right !important;" type="submit" class="btn btn-primary btn-round">Next
                    Step</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>