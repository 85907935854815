<div class="section-card">
  <div class="row m-3" style="justify-content: end;">
    <div class="col-10"></div>
    <button class="col-2 btn btn-primary" style="float: right; width: 100px;" routerLink="/acr-database/acr-details/details/User">
      Back
    </button>
  </div>
  <div class="row FlatCard shadow border-radius6 m-3">
    <div class="card-header">
      <h3 class="card-title">Applied Role Details</h3>
    </div>
    <div class="card-body p-3">
      <div>
        <table class="table">
          <thead class="text-black">
            <th style="text-align: center;">Sr. No</th>
            <th style="text-align: center;">Title</th>
            <th style="text-align: center;">Day Rate</th>
            <th style="text-align: center;">48 hours</th>
            <th style="text-align: center;">Seven Days</th>
            <th style="text-align: center;">Documents</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of tableData; let index = index">
              <tr>
                <td style="text-align: center;">{{ index + 1 }}</td>
                <td style="text-align: center;">{{ item?.title }}</td>
                <td style="text-align: center;">{{ item?.day_rate }}</td>
                <td style="text-align: center;">{{ item?.four_hour }}</td>
                <td style="text-align: center;">{{ item?.seven_hour }}</td>
                <td style="text-align: center;">
                  <ng-container *ngFor="let cvData of item?.cv; let cvindex = index">
                    <button class="btn btn-sm btn-primary m-1" style="margin: 2px;" (click)="openDocument(cvData)">Document {{ cvindex + 1}}</button>
                  </ng-container>
                  </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
