<section class="section-accordian-card">
  <div class="container">
    <h4 style="text-align: center;"><strong>Future Roles</strong></h4>
    <p class=" mb-4 text-center text-bold">
      Please select a contract from the options below to view available Positions.
    </p>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            Contract 1 - Location Manchester / Swindon / India
          </button>
        </h2>
        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p class="text-center mb-4 text-bold">
              Please select the Job Titles you're interested in from the options below for each contract. <br>You may
              choose multiple job titles .
            </p>
            <div class="table-responsive">
              <table class="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th [width]="200" scope="col">Job Title</th>
                    <th scope="col">Job Description</th>
                    <th [width]="250" scope="col">Can you Supply for this Role?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let role of manchesterrolesTableData; let i = index">
                    <td>
                      <label class="form-check-label" for="checkbox{{ i + 1 }}" [innerHTML]="role.role"></label>
                    </td>
                    <td>
                      <a ngbTooltip="{{ role?.description }}">
                        <span>
                          {{ (role?.description ?? '').length > 200
                          ? (role?.description | slice: 0:200) + '... '
                          : role?.description }}
                          <span *ngIf="(role?.description ?? '').length > 200" style="color: #34b5b8 ;">Read more</span>
                        </span>
                      </a>
                    </td>
                    <td class="text-center">
                      <label>
                        <input type="radio" name="choice{{ i }}" value="yes" (click)="openModal(role)" data-bs-toggle="modal" data-bs-target="#loginDetailModal">
                          <!-- data-bs-toggle="modal" data-bs-target="#loginDetailModal" -->
                        Yes
                      </label>&nbsp;
                      <label>
                        <input type="radio" name="choice{{ i }}" value="no">
                        No
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            Contract 2 - Northern Ireland / Swindon / India
          </button>
        </h2>
        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p class="text-center mb-4 text-bold">
              Please select the Job Titles you're interested in from the options below for each contract. <br>You may
              choose multiple job titles .
            </p>
            <div class="table-responsive">
              <table class="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th [width]="200" scope="col">Job Title</th>
                    <th scope="col">Job Description</th>
                    <th [width]="250" scope="col">Can you Supply for this Role?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let role of manchesterrolesTableData; let i = index">
                    <td>
                      <label class="form-check-label" for="checkbox{{ i + 1 }}" [innerHTML]="role.role"></label>
                    </td>
                    <td>
                      <a ngbTooltip="{{ role?.description }}">
                        <span>
                          {{ (role?.description ?? '').length > 200
                          ? (role?.description | slice: 0:200) + '... '
                          : role?.description }}
                          <span *ngIf="(role?.description ?? '').length > 200" style="color: #34b5b8 ;">Read more</span>
                        </span>
                      </a>
                    </td>
                    <td class="text-center">
                      <label>
                        <input type="radio" name="choice{{ i }}" value="yes" (click)="openModal(role)"
                          data-bs-toggle="modal" data-bs-target="#loginDetailModal">
                        Yes
                      </label>&nbsp;
                      <label>
                        <input type="radio" name="choice{{ i }}" value="no">
                        No
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            Contract 3 Public Sector Pool - Location : All over UK / Swindon / India
          </button>
        </h2>
        <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p class="text-center mb-4 text-bold">
              Please select the Job Titles you're interested in from the options below for each contract. <br>You may
              choose multiple job titles .
            </p>
            <div class="table-responsive">
              <table class="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th [width]="200" scope="col">Job Title</th>
                    <th scope="col">Job Description</th>
                    <th [width]="250" scope="col">Can you Supply for this Role?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let role of manchesterrolesTableData; let i = index">
                    <td>
                      <label class="form-check-label" for="checkbox{{ i + 1 }}" [innerHTML]="role.role"></label>
                    </td>
                    <td>
                      <a ngbTooltip="{{ role?.description }}">
                        <span>
                          {{ (role?.description ?? '').length > 200
                          ? (role?.description | slice: 0:200) + '... '
                          : role?.description }}
                          <span *ngIf="(role?.description ?? '').length > 200" style="color: #34b5b8 ;">Read more</span>
                        </span>
                      </a>
                    </td>
                    <td class="text-center">
                      <label>
                        <input type="radio" name="choice{{ i }}" value="yes" (click)="openModal(role)"
                          data-bs-toggle="modal" data-bs-target="#loginDetailModal">
                        Yes
                      </label>&nbsp;
                      <label>
                        <input type="radio" name="choice{{ i }}" value="no">
                        No
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="update ml-auto mr-auto">
        <button type="button" style="float: left;" routerLink="/acr/acr-register"
          class="btn btn-primary btn-round">Back</button>
        <button type="button" style="float: right;" class="btn btn-primary btn-round" (click)="submit()">Next
          Step</button>
      </div>
    </div>
  </div>
</section>


<div class="modal fade" id="loginDetailModal" tabindex="-1" aria-labelledby="ViewLogin" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewLogin">Agency Capacity For {{ selectedJobTitle }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="supplyform" (ngSubmit)="supplyonSubmit()">
          <div class="row">
            <div class="col-md-4">
              <label>No. of Resources Can Be Sourced</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Within 48 hours <span style="color: red;"> *</span> :</label>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <input type="text" (keypress)="NumberOnly($event)" formControlName="four_hour" class="form-control"
                  placeholder="Within 48 Hours">
                <span class="text-danger" *ngIf="
                    supplyform.controls?.['four_hour']?.touched &&
                    supplyform.controls?.['four_hour']?.errors?.['required']">
                  Please enter no. of resources.
                </span>
                <span class="text-danger" *ngIf="
                    supplyform.controls?.['four_hour']?.touched &&
                    supplyform.controls?.['four_hour']?.errors?.['pattern']">
                  Please enter valid format
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Within 7 days <span style="color: red;"> *</span> :</label>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <input type="text" (keypress)="NumberOnly($event)" formControlName="seven_hour" class="form-control"
                  placeholder="Within 7 Days">
                <span class="text-danger" *ngIf="
                    supplyform.controls?.['seven_hour']?.touched &&
                    supplyform.controls?.['seven_hour']?.errors?.['required']">
                  Please enter no. of resources.
                </span>
                <span class="text-danger" *ngIf="
                    supplyform.controls?.['seven_hour']?.touched &&
                    supplyform.controls?.['seven_hour']?.errors?.['pattern']">
                  Please enter valid format
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Expected Day Rate<span style="color: red;"> *</span> :</label>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <input type="text" (keypress)="NumberOnly($event)" formControlName="day_rate" class="form-control"
                  placeholder="Enter Expected Day Rate">
                <span class="text-danger" *ngIf="
                    supplyform.controls?.['day_rate']?.touched &&
                    supplyform.controls?.['day_rate']?.errors?.['required']">
                  Please enter your expected Day Rate.
                </span>
                <span class="text-danger" *ngIf="
                    supplyform.controls?.['day_rate']?.touched &&
                    supplyform.controls?.['day_rate']?.errors?.['pattern']">
                  Please enter your valid expected Day Rate.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Upload Sample CV <span style="color: red;"> *</span> :</label>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <input type="file" (change)="fileUpload($event, 'fileInput1')" class="form-control"
                  style="height: 45px !important; opacity: 1;">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <input type="file" (change)="fileUpload($event, 'fileInput2')" class="form-control"
                  style="height: 45px !important; opacity: 1;">
              </div>
            </div>
          </div>
          <!-- <div class="text-center text-bold" style="color: #FFC107;">If you have got more CV's , send an email to …
          </div> -->

          <div class="mt-3 row d-flex justify-content-end">
            <button type="submit" class="btn btn-primary" style="width: 100px !important;"
              [disabled]="supplyform.invalid" data-bs-dismiss="modal" aria-label="Close">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>