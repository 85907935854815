<div class="section-card">
  <div class="m-3">
    <button class="btn btn-primary" *ngIf="!showFilter" (click)="showFilter = !showFilter">
      Show Filter
    </button>
    <button class="btn btn-primary" *ngIf="showFilter" (click)="showFilter = !showFilter">
      Hide Filter
    </button>
    <button class="btn btn-primary" style="float: right;" routerLink="/acr-database/acr-database-list">
      Back
    </button>
  </div>

  <!-- Start User Filter -->
  <ng-container *ngIf="pageType == 'User'">
    <div class="row m-3" *ngIf="showFilter">
      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Search All" [(ngModel)]="userFilter.keyword" />
        </div>
      </div>
      <!-- <div class="col-4">
        <div class="form-group">
          <select class="form-control" [(ngModel)]="userFilter.UKDrivinglicense">
            <option value="" disabled selected hidden>Uk Driving License</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
      </div> -->
      <!-- <div class="col-4">
        <div class="form-group">
          <select class="form-control" [(ngModel)]="userFilter.nationality">
            <option value="" disabled selected hidden>Select Nationality</option>
            <option value="british">British</option>
            <option value="other">Others</option>
          </select>
        </div>
      </div> -->
      <!-- <div class="col-4">
        <div class="form-group">
          <select class="form-control" [(ngModel)]="userFilter.currentWork">
            <option value="" disabled selected hidden>Current Working As</option>
            <option value="full-time">Full Time</option>
            <option value="part-time">Part Time</option>
            <option value="not-working">Not working</option>
          </select>
        </div>
      </div> -->
      <div class="col-4">
        <button type="button" class="btn btn-primary" (click)="getTableDetails()">Submit</button>
        <button type="button" class="btn btn-primary" (click)="resetFilter()">Reset</button>
      </div>
    </div>
  </ng-container>

  <!-- JOb Filter -->
  <ng-container *ngIf="pageType == 'Job'">
    <div class="row m-3" *ngIf="showFilter">
      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Search All" [(ngModel)]="JobFilter.keyword" />
        </div>
      </div>
      <!-- <div class="col-4">
        <div class="form-group">
          <select class="form-control" [(ngModel)]="JobFilter.status">
            <option value="" disabled selected hidden>Status</option>
            <option value="inactive">Inactive</option>
            <option value="activr">Active</option>
          </select>
        </div>
      </div> -->
      <!-- <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="location" [(ngModel)]="JobFilter.location" />
        </div>
      </div> -->

      <div class="col-4">
        <button type="button" class="btn btn-primary" (click)="getTableDetails()">Submit</button>
        <button type="button" class="btn btn-primary" (click)="resetFilter()">Reset</button>
      </div>
    </div>
  </ng-container>

  <!-- Start Database Details -->
  <div class="FlatCard shadow border-radius6 m-3">
    <div class="row card-header">
      <div class="col-6 text-start d-flex align-items-center">
        <h2 class="card-title">{{ pageType }} Details</h2>
      </div>
      <div class="col-6 text-end">
        <button type="button" class="btn btn-primary" style="padding: 0px 10px !important" (click)="downloadAsExcel()">
          Download Excel
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <ng-container *ngIf="pageType == 'User'">
          <table class="table">
            <thead>
              <th *ngFor="let header of tableHeader">{{ header }}</th>
            </thead>
            <tbody>
              <ng-container
                *ngFor="let item of tableData  |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let index = index">
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>{{ item?.agencyName }}</td>
                  <td>{{ item?.location }}</td>
                  <td>{{ item?.numberOfBranchesInUK }}</td>
                  <td>{{ item?.personName }}</td>
                  <td>{{ item?.personDesignation }}</td>
                  <td>{{ item?.personEmail }}</td>
                  <td>{{ item?.phoneNumberCountryCode }}</td>
                  <td>{{ item?.phoneNumber }}</td>
                  <td>{{ item?.secondaryContactName || 'N/A' }}</td>
                  <td>{{ item?.secondaryDesignation || 'N/A' }}</td>
                  <td>{{ item?.secondaryEmail || 'N/A' }}</td>
                  <td>{{ item?.secondaryPhoneNumberCountryCode || 'N/A' }}</td>
                  <td>{{ item?.secondaryPhoneNumber || 'N/A' }}</td>
                  <td>{{ item?.contactTime || 'N/A' }}</td>
                  <td>{{ item?.password_reset ? 'Yes' : 'No' }}</td>
                  <td>
                    <ng-container *ngIf="item?.appliedRole?.length > 0; else noRoles">
                      <button class="btn btn-sm btn-primary" (click)="viewRoleData(item)">View Role</button>
                    </ng-container>
                    <ng-template #noRoles>No Roles Applied</ng-template>
                  </td>
                  <td>{{ item?.createdAt | date: 'short' }}</td>
                  <td>{{ item?.updatedAt | date: 'short' }}</td>
                  <td>
                    <ng-container *ngIf="item?.profile; else noProfile">
                      <button type="button" class="btn w-100" (click)="openDocument(item?.profile)">
                        <i class="fa fa-eye"></i> View Profile
                      </button>
                    </ng-container>
                    <ng-template #noProfile>No Profile</ng-template>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="pageType == 'Job'">
          <table class="table">
            <thead>
              <th *ngFor="let header of tableHeader">{{ header }}</th>
            </thead>
            <tbody>
              <ng-container
                *ngFor="let item of tableData  |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let index = index">
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>{{ item?.job_title }}</td>
                  <td>{{ item?.no_of_roles }}</td>
                  <td>{{ item?.start_date | date: 'short' }}</td>
                  <td>{{ item?.publish_date | date: 'short' }}</td>
                  <td>{{ item?.client_name }}</td>
                  <td>{{ item?.location }}</td>
                  <td>{{ item?.day_rate }}</td>
                  <td>
                    <ng-container *ngIf="item?.applicants?.length > 0; else noApplicants">
                      <ul>
                        <li *ngFor="let applicant of item?.applicants">
                          {{ applicant?.userInfo?.agencyName }}
                        </li>
                      </ul>
                      <button class="btn btn-sm btn-primary" (click)="appliedRoleData(item)">
                        View applicants
                      </button>
                    </ng-container>
                    <ng-template #noApplicants>No Applicants</ng-template>
                  </td>
                  <!-- <td>{{ item?.upload?.key }}</td> -->
                  <td>
                    <a [href]="item?.upload?.url" target="_blank">Download</a>
                  </td>
                  <td>{{ item?.timerEnd | date: 'short' }}</td>
                  <td>{{ item?.job_time_left }}</td>
                  <td>{{ item?.status }}</td>
                  <td>
                    <button (click)="deleteJob(item?._id)" class="btn btn-sm btn-danger">
                      <i class="bi bi-trash-fill"></i> Delete
                    </button>
                  </td>

                </tr>
              </ng-container>
            </tbody>
          </table>
        </ng-container>
      </div>
      <div class="row mt-3 align-items-center">
        <div class="row">
          <div class="pagination">
            <pagination-controls (pageChange)="paginate($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>