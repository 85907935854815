<section class="section-card">
    <div class="container">
        <h2 class="text-center mb-4">Roles in Demand</h2>
        <p class="text-center mb-4 text-bold">
            Please select a Contract from the options below to view available roles.
        </p>
        <div class="row">
            <div class="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="FlatCard shadow border-radius6" routerLink="/cir/cir-roles-demand-manchester-card">
                    <div class="card-body text-center p-3">
                        <img src="../../../../assets/img/location.png" width="64" class="mb-3" />
                        <p class="text-bold">Contract 1 - Location Manchester / Swindon / India </p>
                        <!-- <p>Location: Manchester</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="FlatCard shadow border-radius6" routerLink="/cir/cir-roles-demand-northern-ireland-card">
                    <div class="card-body text-center p-3">
                        <img src="../../../../assets/img/location.png" width="64" class="mb-3" />
                        <p class="text-bold">Contract 2 - Northern Ireland / Swindon / India</p>
                        <!-- <p>Location: Northern Ireland </p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="FlatCard shadow border-radius6" routerLink="/cir/cir-roles-demand-all-over-uk-card">
                    <div class="card-body text-center p-3">
                        <img src="../../../../assets/img/location.png" width="64" class="mb-3" />
                        <p class="text-bold">Contract 3 Public Sector Pool - Location : All over UK/ Swindon / India
                        </p>
                        <!-- <p>Location: All over UK</p> -->
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="FlatCard shadow border-radius6" routerLink="/cir/cir-roles-glasgow-card">
                    <div class="card-body text-center p-3">
                        <img src="../../../../assets/img/location.png" width="64" class="mb-3" />
                        <p class="text-bold">Contract 4 - Location Glasgow / Swindon / India
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="FlatCard shadow border-radius6" routerLink="/cir/cir-roles-birmingham-swindon-card">
                    <div class="card-body text-center p-3">
                        <img src="../../../../assets/img/location.png" width="64" class="mb-3" />
                        <p class="text-bold">Contract 5 - Location Birmingham / Swindon / India
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="FlatCard shadow border-radius6" routerLink="/cir/cir-roles-ireland-swindon-card">
                    <div class="card-body text-center p-3">
                        <img src="../../../../assets/img/location.png" width="64" class="mb-3" />
                        <p class="text-bold">Contract 6 - Location Ireland / Swindon / India
                        </p>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="FlatCard shadow border-radius6" routerLink="/cir/cir-roles-contract-ireland-swindon-card">
                    <div class="card-body text-center p-3">
                        <img src="../../../../assets/img/location.png" width="64" class="mb-3" />
                        <p class="text-bold">Contract 7 - Location Ireland / Swindon / India
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="FlatCard shadow border-radius6" routerLink="/cir/cir-roles-coventry-swindon-card">
                    <div class="card-body text-center p-3">
                        <img src="../../../../assets/img/location.png" width="64" class="mb-3" />
                        <p class="text-bold">Contract 8 - Location Coventry / Swindon / India
                        </p>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>